<template>
  <div class="grid-4-8 m-b-3 w-95p m-t-2">
    <div>
      <router-link :to="backTo" class="grid h-100p text-decoration-none" v-if="backTo">
        <button class="action-button m-r-1">back</button>
      </router-link>
    </div>

    <router-link v-if="nextCondition" :to="to" class="grid h-100p text-decoration-none">
      <button class="primary-button place-y-end">next step</button>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "BottomNav",
  props: {
    to: String,
    backTo: String,
    nextCondition: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>

</style>