<template>
  <article class="scroller w-95p d-hidden md:d-block">
    <Modal v-model:is-open="showModal">
      <h3 class="p-l-2 text-primary d-flex">
        <span class="f-1">General</span>
        <span>Steps 1-3</span>
      </h3>
      <div class="grid-4-4-4 p-2 ">
        <div class="font-strong">{{ $store.state.boatModel.title }}</div>
        <div v-html="$store.state.boatModel.description"></div>
        <div class="place-x-end font-strong">{{ formatPrice($store.state.boatModel.price || 0) }}</div>
      </div>
      <div class="grid-4-4-4 p-2 b-t-1 b-gray-50">
        <div class="font-strong">{{ $store.state.exterior.title || 'No exterior style selected' }}</div>
        <div v-html="$store.state.exterior.description"></div>
        <div class="place-x-end font-strong">{{ formatPrice($store.state.exterior.price || 0) }}</div>
      </div>
      <div class="grid-4-4-4 p-2 b-t-1 b-b-1 b-gray-50">
        <div class="font-strong">{{ $store.state.interior.title || 'No interior style selected' }}</div>
        <div v-html="$store.state.interior.description"></div>
        <div class="place-x-end font-strong">{{ formatPrice($store.state.interior.price || 0) }}</div>
      </div>
      <h3 class="p-l-2 text-primary d-flex">
        <span class="f-1">Interior modules</span>
        <span>Step 4</span>
      </h3>
      <div class="grid-4-4-4 p-2 b-gray-50" v-for="module in $store.state.selectedModules" >
        <div class="font-strong">{{ module.title }}</div>
        <div v-html="module.description"></div>
        <div class="place-x-end font-strong">{{ formatPrice(module.price) }}</div>
      </div>
      <h3 class="p-l-2 text-primary d-flex">
        <span class="f-1">Mobility</span>
        <span>Step 5</span>
      </h3>
      <div class="grid-4-4-4 p-2 b-t-1 b-b-1 b-gray-50" v-if="$store.state.motor.id">
        <div class="font-strong">{{ $store.state.motor.title }}</div>
        <div v-html="$store.state.motor.description"></div>
        <div class="place-x-end font-strong">{{ formatPrice($store.state.motor.price || 0) }}</div>
      </div>
      <h3 class="p-l-2 text-primary d-flex">
        <span class="f-1">Capability</span>
        <span>Step 6</span>
      </h3>
      <div class="grid-4-4-4 p-2 b-b-1 b-gray-50" v-if="$store.state.electricity.id">
        <div class="font-strong">{{ $store.state.electricity.title }}</div>
        <div v-html="$store.state.electricity.description"></div>
        <div class="place-x-end font-strong">{{ formatPrice($store.state.electricity.price || 0) }}</div>
      </div>
      <div class="grid-4-4-4 p-2 b-b-1 b-gray-50" v-if="$store.state.battery.id">
        <div class="font-strong">{{ $store.state.battery.title }}</div>
        <div v-html="$store.state.battery.description"></div>
        <div class="place-x-end font-strong">{{ formatPrice($store.state.battery.price || 0) }}</div>
      </div>
      <div class="grid-4-4-4 p-2 b-b-1 b-gray-50">
        <div class="font-strong">{{ $store.state.treatment.title || 'No water treatment'}}</div>
        <div v-html="$store.state.treatment.description"></div>
        <div class="place-x-end font-strong">{{ formatPrice($store.state.treatment.price || 0) }}</div>
      </div>
      <div class="grid-4-4-4 p-2 b-b-1 b-gray-50">
        <div class="font-strong">{{ $store.state.waste.title || 'No waste water treatment'}}</div>
        <div v-html="$store.state.waste.description"></div>
        <div class="place-x-end font-strong">{{ formatPrice($store.state.waste.price || 0) }}</div>
      </div>
      <h3 class="p-l-2 text-primary">Add-Ons</h3>
      <div class="grid-4-4-4 p-2 b-gray-50" v-for="addOn in $store.state.addOns">
        <div class="font-strong">{{ addOn.title }}</div>
        <div v-html="addOn.description"></div>
        <div class="place-x-end font-strong">{{ formatPrice(addOn.price) }}</div>
      </div>
    </Modal>
    <section>
      <div class="grid">
        <i @click="showModal=true" class="las la-info-circle place-x-end m-b-2 cursor-pointer" title="info"></i>
      </div>
      <div>
        <router-link to="/" class="bg-gray-lighter-50 p-x-3 p-y-1 d-block text-decoration-none text-black m-b-1" :class="{'raise-1-gray-50':step===1}">
          <div class="d-flex">
            <div class="f-1">1. Model</div>
            <div v-if="$store.state.boatModel.price">{{ formatPrice($store.state.boatModel.price) }}</div>
          </div>
        </router-link>
        <div class="d-flex list-item" v-if="step===1">
          <div class="f-1">{{ $store.state.boatModel.title }}</div>
          <div class="font-strong" v-if="$store.state.boatModel.price">{{
              formatPrice($store.state.boatModel.price)
            }}
          </div>
        </div>

      </div>
      <div >
        <router-link to="/exterior" class="bg-gray-lighter-50 p-x-3 p-y-1 d-block text-decoration-none text-black m-b-1" :class="{'raise-1-gray-50':step===2}">
          <div class="d-flex">
            <div class="f-1">2. Exterior</div>
            <div>{{ $store.state.exterior.price > 0 ? formatPrice($store.state.exterior.price) : 'inclusive' }}</div>
          </div>

        </router-link>
        <div class="d-flex list-item" v-if="step===2">
          <div class="f-1">{{ $store.state.exterior.title }}</div>
          <div class="font-strong">{{ $store.state.exterior.price > 0 ? formatPrice($store.state.exterior.price): 'inclusive' }}</div>
        </div>
      </div>
      <div >
        <router-link to="/interior" class="bg-gray-lighter-50 p-x-3 p-y-1 d-block text-decoration-none text-black m-b-1" :class="{'raise-1-gray-50':step===3}">
          <div class="d-flex">
            <div class="f-1">3. Interior</div>
            <div>{{ $store.state.interior.title }}</div>
          </div>

        </router-link>
        <div class="d-flex list-item" v-if="step===3">
          <div class="f-1">{{ $store.state.interior.title }}</div>
          <div class="font-strong">inclusive</div>
        </div>
      </div>
      <div >
        <router-link to="/modules" class="bg-gray-lighter-50 p-x-3 p-y-1 d-block text-decoration-none text-black m-b-1"
             :class="{'raise-1-gray-50':step===4}">
          <div class="d-flex">
            <div class="f-1">4. Modules</div>
            <div>{{ formatPrice($store.getters.totalModulePrice) }}</div>
          </div>
        </router-link>

        <div v-if="step===4" v-for="i in $store.state.boatModel.num_modules">
          <div class="d-flex list-item" v-if="$store.state.selectedModules[numberKeys[i-1]]">
            <div class="f-1">
              Module {{ i }}: <br>{{ $store.state.selectedModules[numberKeys[i - 1]].title }}
            </div>
            <div class="font-strong">{{ formatPrice($store.state.selectedModules[numberKeys[i - 1]].price) }}</div>
          </div>

        </div>
      </div>
      <div >
        <router-link to="/driving" class="bg-gray-lighter-50 p-x-3 p-y-1 d-block text-decoration-none text-black m-b-1" :class="{'raise-1-gray-50':step===5}">
          <div class="d-flex">
            <div class="f-1">5. Mobility</div>
            <div v-if="$store.state.motor.price > 0">{{ formatPrice($store.state.motor.price) }}</div>
            <div v-else>Inclusive</div>
          </div>
        </router-link>
        <div class="d-flex  list-item" v-if="step===5">
          <div class="f-1">{{ $store.state.motor.title }}</div>
          <div class="font-strong">{{ formatPrice($store.state.motor.price) }}</div>
        </div>
      </div>
      <div >
        <router-link to="/supply" class="bg-gray-lighter-50 p-x-3 p-y-1 d-block text-decoration-none text-black m-b-1" :class="{'raise-1-gray-50':step===6}">
          <div class="d-flex">
            <div class="f-1">6. Supply</div>
            <div>{{ formatPrice($store.getters.totalSupplyPrice) }}</div>
          </div>
        </router-link>
        <div v-if="step===6">
          <div class="p-y-1 font-strong p-l-4">Electricity</div>
          <div class="d-flex list-item">
            <div class="f-1">{{ $store.state.electricity.title }}</div>
            <div class="font-strong">{{ formatPrice($store.state.electricity.price) }}</div>
          </div>
          <div class="d-flex list-item">
            <div class="f-1">{{ $store.state.battery.title }}</div>
            <div class="font-strong">{{ formatPrice($store.state.battery.price) }}</div>
          </div>
          <div class="p-y-1 font-strong p-l-4">Water</div>
          <div class="d-flex list-item">
            <div class="f-1">{{ $store.state.water.title }}</div>
            <div class="font-strong">{{ formatPrice($store.state.water.price) }}</div>
          </div>
          <div class="d-flex list-item">
            <div class="f-1">{{ $store.state.treatment.title || 'No water treatment' }}</div>
            <div class="font-strong">{{ formatPrice($store.state.treatment.price || 0) }}</div>
          </div>
          <div class="d-flex list-item">
            <div class="f-1">{{ $store.state.waste.title || 'No waste water treatment' }}</div>
            <div class="font-strong">{{ formatPrice($store.state.waste.price || 0) }}</div>
          </div>
        </div>

      </div>
      <div >
        <router-link to="/add-ons" class="bg-gray-lighter-50 p-x-3 p-y-1 d-block text-decoration-none text-black m-b-1" :class="{'raise-1-gray-50':step===7}">
          <div class="d-flex">
            <div class="f-1">Add Ons</div>
            <div v-if="$store.getters.totalAddOnPrice > 0">{{ formatPrice($store.getters.totalAddOnPrice) }}</div>
            <div v-else>none selected</div>
          </div>
        </router-link>
        <div class="d-flex list-item" v-if="step===7" v-for="addOn in $store.state.addOns">
          <!--        <div class="m-r-1"><button class="cursor-pointer b-rounded-3 b-1 bg-warning text-white">x</button></div>-->
          <div class="f-1">{{ addOn.title }}</div>
          <div class="font-strong">{{ formatPrice(addOn.price) }}</div>
        </div>
      </div>
    </section>

  </article>

</template>

<script setup>
import {useStore} from "vuex";
import {ref} from "vue";
import Modal from "@/components/Modal"

const store = useStore();
const numberKeys = ref(['zero', 'one', 'two', 'three', 'four', 'five', 'six'])
const showModal = ref(false)
const {step} = defineProps({
  step: {
    type: Number,
    default: 1
  }
})

function formatPrice(price) {
    if(!price || price === 0){
        return 'included'
    }
  return Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(price)
}

function calculateModifier(modifier) {
  return store.state.boatModel.price / 100 * store.state[modifier]['price-modifier'];
}
</script>

<style scoped>
    .list-item {
        font-size: 14px;
        padding: .2rem .1rem .2rem 1rem;
    }

</style>