<template>
  <section style="min-height: 305px">
    <div class="p-x-2 cursor-pointer text-center font-lg" v-if="!hideHeadline"><slot>Make your selection</slot></div>
    <div class="d-flex m-t-2">
      <div
          :class="{'b-primary text-primary': name===selectedTab, 'b-gray-lighter': name !==selectedTab, 'text-gray-lighter-50': items.length<1}"
          class="m-x-1 p-x-2 b-b-1 cursor-pointer f-1 text-center"
          @click="$emit('update:selectedTab',name)"
          v-for="(items, name) in multiItems">{{ name.toUpperCase() }} ({{ items.length }})
      </div>
    </div>
    <shop :highlighted="highlighted" :item="item" :show-inclusive="showInclusive" :hide-price="hidePrice" v-if="selectedTab" :items="multiItems[selectedTab]" image-property="module-image" :price-type="priceType"
          v-model:item="selectItem" @update:item="$emit('update',{category: selectedTab, item: selectItem})"/>
  </section>
</template>

<script setup>
import Shop from "@/components/Shop"
import {ref, watch} from "vue";

const {multiItems, priceType, selectedTab, hidePrice, showInclusive, item, highlighted, hideHeadline} = defineProps({
  multiItems: Object,
  priceType: {
    type: String,
    default: 'price'
  },
  selectedTab: {
    type: String,
  },
  hidePrice: {
    type: Boolean,
    default: false
  },
  showInclusive: {
    type: Boolean,
    default: false
  },
  item:{
    type: Object,
    default: {id:null}
  },
  highlighted: {
    type: Array,
    default: []
  },
  hideHeadline: {
      type: Boolean,
      default: false
  }
})
const emit = defineEmits();
const selected = ref(null);
const selectItem = ref(null);


/*watch(selectItem, newVal => {
  if (newVal) {
    emit('update', {category: selectedTab, item: selectItem.value})
    // selectItem.value = null;
  }
})*/
/*watch(multiItems, ()=>{
  selected.value = Object.keys(multiItems)[0];
  console.log(selected.value)
})*/
</script>

<style scoped>

</style>