<template>
  <div class="m-y-3">
    <div class=" m-x-3">
      <div class="md:d-flex scroller p-b-2" style="overflow-x: auto">
        <div class="grid-4-8 m-x-1 b-1 b-gray-light cursor-pointer m-b-1"
             :style="{flexBasis: items.length > 3 ? '24%' : '32%'}" style="height: 210px; flex-shrink: 0; flex-grow:0"
             :class="{'bg-gray-lighter-50': (item && single.id === item.id) || props.highlighted.find(x => x.id === single.id)}"
             v-for="single in props.items" @click="$emit('update:item', single)">
          <div class="p-3">
            <div class="w-100p h-100p"
                 :style="{backgroundImage: 'url(' +orgamaxImage(single)+')', backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }"></div>
          </div>
          <div class="d-flex" style="flex-direction: column; height: 210px">
            <h4 class="m-t-1">{{ single.title }}</h4>
            <div class="font-sm scroller f-1" v-if="single.description !== '0'" v-html="single.description"></div>
            <div class="grid">
              <p class="place-x-end p-r-3" v-if="!props.hidePrice&&single.price>0">
                {{ priceType === 'price' ? formatCurrency(single.price) : usedPrice(single) }}</p>
              <p class="place-x-end p-r-3" v-if="props.showInclusive&&single.price===0">Inclusive</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import {useStore} from "vuex";
import {formatCurrency, modifierPriceCalculation} from "@/composables/currency";
import {orgamaxImage} from "@/composables/orgamax";

const emits = defineEmits(['update:item'])

const store = useStore();
const props = defineProps({
  items: Array,
  hidePrice: {
    type: Boolean,
    default: false
  },
  priceType: {
    type: String,
    default: 'price'
  },
  showInclusive: {
    type: Boolean,
    default: false
  },
  item: {
    type: Object,
    default: {
      id: null
    }
  },
  highlighted: {
    type: Array,
    default: []
  }
})

function usedPrice(item) {
  return formatCurrency(modifierPriceCalculation(store.state.boatModel.price, item[props.priceType]))
}

</script>
<style>
ul {
  padding-left: 20px;
  list-style: none;
  position: relative;
}

li {
  margin-bottom: 9px;
}

li:before {
  position: absolute;
  content: "✔️";
  color: transparent;
  text-shadow: 0 0 0 #34679B;
  left: -8px;
}
</style>
